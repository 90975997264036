












































































































































































































































































































































































































































































































































































































































































































































































































import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  and,
  minValue,
  required,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/ProgrammaticVoucherController'
import routeController, { Data } from '@/app/ui/controllers/RouteController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import VoucherTextInput from '../../components/VoucherTextInput/index.vue'
import VoucherDropdown from '../../components/VoucherDropdown/index.vue'
import VoucherDatePicker from '../../components/VoucherDatePicker/index.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { ProgrammaticVoucher } from '@/domain/entities/ProgrammaticVoucher'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import * as constantData from '@/app/infrastructures/misc/Constants/programaticVoucher'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'

interface validationInterface {
  form: {
    pointAmount: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    discountAmount: {
      requiredIf: ValidationRule
    }
    discountMinimum: {
      requiredIf: ValidationRule
    }
    discountPercentage: {
      requiredIf: ValidationRule
    }
    discountPercentageMinimum: {
      requiredIf: ValidationRule
    }
    discountPercentageMaximum: {
      requiredIf: ValidationRule
    }
    cashbackPercentage: {
      requiredIf: ValidationRule
    }
    cashbackPercentageMinimum: {
      requiredIf: ValidationRule
    }
    cashbackPercentageMaximum: {
      requiredIf: ValidationRule
    }
    voucherPurposeInvoiceType: {
      requiredIf: ValidationRule
    }
    expiryDay: {
      required: () => ValidationRule
      minValue: ValidationRule
    }
    limitUsage: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    startDate: {
      requiredIf: ValidationRule
    }
    startTime: {
      requiredIf: ValidationRule
    }
    endDate: {
      requiredIf: ValidationRule
    }
    endTime: {
      requiredIf: ValidationRule
    }
    budgetAmount: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
  }
}
@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    VoucherTextInput,
    VoucherDropdown,
    VoucherDatePicker,
    OptionBox,
    LoadingOverlay,
    Toggle,
  },
})
export default class UpdateProgrammaticVoucher extends Vue {
  controller = controller
  routeController = routeController
  constants = constantData
  maxCount = 30
  isEmptyPointPurposeAndExpire = false
  form = {
    voucherName: '',
    voucherTarget: <DropDownLabelValue<string>>(<unknown>[]),
    selectedUser: '',
    selectedUserFileUrl: '',
    selectedUserFileName: '',
    os: <DropDownLabelValue<string>>(<unknown>[]),
    osValidation: <DropDownLabelValue<string>>(<unknown>[]),
    osValidationVersion: '',
    routeIds: <Data[]>[],
    membershipLevel: <DropDownLabelValue<string>>(<unknown>[]),
    voucherTrigger: <DropDownLabelValue<string>>(<unknown>[]),
    scheduleDate: '',
    scheduleTime: '',
    membershipLevelUp: <DropDownLabelValue<string>>(<unknown>[]),
    limitPerDay: 0,
    triggerInvoiceType: <string[]>[],
    voucherPurposeParent: <DropDownLabelValue<string>>(<unknown>[]),
    voucherPurposeChild: <DropDownLabelValue<string>>(<unknown>[]),
    pointAmount: 0,
    pointExpiry: 0,
    pointPurpose: <string[]>[],
    discountAmount: 0,
    discountMinimum: 0,
    discountPercentage: 0,
    discountPercentageMinimum: 0,
    discountPercentageMaximum: 0,
    cashbackPercentage: 0,
    cashbackPercentageMinimum: 0,
    cashbackPercentageMaximum: 0,
    voucherPurposeInvoiceType: <string[]>[],
    expiryDay: 0,
    limitUsage: 0,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    budgetAmount: <null | number | undefined>null,
  }
  toggleBudgetAmount = false
  successModal = false
  confirmationModal = false
  todayDate = dayjs().format('YYYY-MM-DD')
  hasChanged = {
    pointAmount: false,
    pointExpiry: false,
    pointPurpose: false,
    discountAmount: false,
    discountMinimum: false,
    discountPercentage: false,
    discountPercentageMinimum: false,
    discountPercentageMaximum: false,
    cashbackPercentage: false,
    cashbackPercentageMinimum: false,
    cashbackPercentageMaximum: false,
    voucherPurposeInvoiceType: false,
    expiryDay: false,
    limitUsage: false,
    startDate: false,
    startTime: false,
    endDate: false,
    endTime: false,
    budgetAmount: false,
  }

  created(): void {
    controller.getTriggers()
  }

  @Validations()
  validations(): validationInterface {
    return {
      form: {
        pointAmount: {
          requiredIf: requiredIf(
            () => this.isRedeemToPoint || this.isRedeemToParcelPoint
          ),
          minValue:
            this.isRedeemToPoint || this.isRedeemToParcelPoint
              ? minValue(1)
              : true,
        },
        discountAmount: {
          requiredIf: requiredIf(() => this.isDiscByAmount),
        },
        discountMinimum: {
          requiredIf: requiredIf(() => this.isDiscByAmount),
        },
        discountPercentage: {
          requiredIf: requiredIf(() => this.isDiscByPercent),
        },
        discountPercentageMinimum: {
          requiredIf: requiredIf(() => this.isDiscByPercent),
        },
        discountPercentageMaximum: {
          requiredIf: requiredIf(() => this.isDiscByPercent),
        },
        cashbackPercentage: {
          requiredIf: requiredIf(() => this.isCashback),
        },
        cashbackPercentageMinimum: {
          requiredIf: requiredIf(() => this.isCashback),
        },
        cashbackPercentageMaximum: {
          requiredIf: requiredIf(() => this.isCashback),
        },
        voucherPurposeInvoiceType: {
          requiredIf: and(
            requiredIf(
              () => !this.isRedeemToPoint && !this.isRedeemToParcelPoint
            ),
            (value: Array<unknown>) =>
              !this.isRedeemToPoint && !this.isRedeemToParcelPoint
                ? value.length > 0
                : true
          ),
        },
        expiryDay: { required, minValue: minValue(1) },
        limitUsage: {
          requiredIf: requiredIf(
            () => !this.isRedeemToPoint && !this.isRedeemToParcelPoint
          ),
          minValue:
            !this.isRedeemToPoint && !this.isRedeemToParcelPoint
              ? minValue(1)
              : true,
        },
        startDate: {
          requiredIf: requiredIf(() => !this.isOnScheduleTrigger),
        },
        startTime: {
          requiredIf: requiredIf(() => !this.isOnScheduleTrigger),
        },
        endDate: {
          requiredIf: requiredIf(() => !this.isOnScheduleTrigger),
        },
        endTime: {
          requiredIf: requiredIf(() => !this.isOnScheduleTrigger),
        },
        budgetAmount: {
          requiredIf: requiredIf(() => this.toggleBudgetAmount),
          minValue: this.toggleBudgetAmount
            ? minValue(this.validateBudgetAmount)
            : true,
        },
      },
    }
  }

  get validateBudgetAmount(): number {
    if (this.isDiscByAmount) {
      return this.form.discountAmount
    } else if (this.isDiscByPercent) {
      return this.form.discountPercentageMaximum
    } else if (this.isCashback) {
      return this.form.cashbackPercentageMaximum
    } else if (this.isRedeemToPoint || this.isRedeemToParcelPoint) {
      return this.form.pointAmount
    }
    return 1
  }

  get errorMessageBudgetAmount(): string | undefined {
    if (
      !this.$v.form.budgetAmount?.requiredIf &&
      this.hasChanged.budgetAmount
    ) {
      return 'Budget amount can’t be 0'
    } else if (
      !this.$v.form.budgetAmount?.minValue &&
      this.hasChanged.budgetAmount
    ) {
      return 'Budget amount must be higher than the point/discount amount'
    }
    return undefined
  }

  @Watch('controller.triggerData')
  onTriggerDataChanged(): void {
    controller.getProgrammaticVoucherDetail(this.$route.params.id)
  }

  @Watch('routeController.cityData')
  onRouteDataChanged(): void {
    this.form.routeIds = routeController.cityData.filter(item =>
      this.voucherDetail.routeIds?.includes(item.value.toString())
    )
  }

  @Watch('voucherDetail')
  onVoucherDetailChanged(val: ProgrammaticVoucher): void {
    if (val.voucherTarget === <string>constantData.UserType.SPECIFIC_ORIGIN) {
      routeController.getCityList()
    }

    this.form = {
      voucherName: val.voucherName || '',
      voucherTarget:
        constantData.TARGET_USERS.find(
          item => item.value === val.voucherTarget
        ) || <DropDownLabelValue<constantData.UserType>>(<unknown>[]),
      selectedUser: val.selectedUser || '',
      selectedUserFileUrl: val.selectedUserUrl || '',
      selectedUserFileName: val.selectedUserFileName || '',
      os:
        constantData.PLATFORM_OS_OPTIONS.find(item => item.value === val.os) ||
        <DropDownLabelValue<constantData.PlatformOsType>>(<unknown>[]),
      osValidation:
        constantData.MEASUREMENT_OPTIONS.find(
          item => item.value === val.osValidation
        ) || <DropDownLabelValue<constantData.MeasurementType>>(<unknown>[]),
      osValidationVersion: val.osValidationVersion || '',
      routeIds: <Data[]>[],
      membershipLevel:
        constantData.MEMBERSHIP_OPTIONS.find(
          item => item.value === val.membershipLevel
        ) ||
        <DropDownLabelValue<constantData.MembershipLevelType>>(<unknown>[]),
      voucherTrigger:
        this.triggerOptions.find(item => item.value === val.voucherTrigger) ||
        <DropDownLabelValue<constantData.TriggerType>>(<unknown>[]),
      scheduleDate: Utils.formatDate(val.scheduledAt || '', 'YYYY-MM-DD', ''),
      scheduleTime: Utils.formatDate(val.scheduledAt || '', 'HH:mm:ss', ''),
      membershipLevelUp:
        constantData.LEVEL_UP_OPTIONS.find(
          item => item.value === val.membershipLevelUp
        ) || <DropDownLabelValue<constantData.LevelUpType>>(<unknown>[]),
      limitPerDay: val.limitPerDay || 0,
      triggerInvoiceType:
        val.triggerInvoiceType?.split(',').filter(item => item != '') ||
        <string[]>[],
      voucherPurposeParent:
        constantData.PURPOSE_OPTIONS.find(
          item => item.value === val.voucherPurposeParent
        ) || <DropDownLabelValue<constantData.PurposeType>>(<unknown>[]),
      voucherPurposeChild:
        constantData.TERM_COND_OPTIONS.find(
          item => item.value === val.voucherPurposeChild
        ) || <DropDownLabelValue<constantData.TermConditionType>>(<unknown>[]),
      pointAmount: val.pointAmount || 0,
      pointExpiry: val.pointExpiry || 0,
      pointPurpose:
        val.pointPurpose?.split(',').filter(item => item != '') || <string[]>[],
      discountAmount: val.discountAmount || 0,
      discountMinimum: val.discountMinimum || 0,
      discountPercentage: val.discountPercentage || 0,
      discountPercentageMinimum: val.discountPercentageMinimum || 0,
      discountPercentageMaximum: val.discountPercentageMaximum || 0,
      cashbackPercentage: val.cashbackPercentage || 0,
      cashbackPercentageMinimum: val.cashbackPercentageMinimum || 0,
      cashbackPercentageMaximum: val.cashbackPercentageMaximum || 0,
      voucherPurposeInvoiceType:
        val.voucherPurposeInvoiceType?.split(',').filter(item => item != '') ||
        <string[]>[],
      expiryDay: val.expiryDay || 0,
      limitUsage: val.limitUsage || 0,
      startDate: Utils.formatDate(val.startDate || '', 'YYYY-MM-DD', ''),
      startTime: Utils.formatDate(val.startDate || '', 'HH:mm:ss', ''),
      endDate: Utils.formatDate(val.endDate || '', 'YYYY-MM-DD', ''),
      endTime: Utils.formatDate(val.endDate || '', 'HH:mm:ss', ''),
      budgetAmount: val.budgetAmount,
    }

    this.toggleBudgetAmount = !!val.budgetAmount
    if (this.form.pointPurpose.length === 0 && this.form.pointExpiry === -1) {
      this.isEmptyPointPurposeAndExpire = true
    }
  }

  @Watch('form.voucherTrigger')
  onChange(): void {
    if (
      !(this.form.voucherTrigger instanceof Array) &&
      this.isOnScheduleTrigger
    ) {
      this.toggleBudgetAmount = false
      this.form.budgetAmount = 0
    }
  }

  get isSelectedUserTarget(): boolean {
    return (
      <constantData.UserType>this.form.voucherTarget.value ===
      constantData.UserType.SELECTED
    )
  }

  get isOriginTarget(): boolean {
    return (
      <constantData.UserType>this.form.voucherTarget.value ===
      constantData.UserType.SPECIFIC_ORIGIN
    )
  }

  get isMembershipTarget(): boolean {
    return (
      <constantData.UserType>this.form.voucherTarget.value ===
      constantData.UserType.MEMBERSHIP_LEVEL
    )
  }

  get isOSTarget(): boolean {
    return (
      <constantData.UserType>this.form.voucherTarget.value ===
      constantData.UserType.SPECIFIC_OS
    )
  }

  get isBudgetAmountNull(): boolean {
    return !!this.voucherDetail.budgetAmount
  }

  get isActive(): boolean {
    return (
      <constantData.StatusType>controller.voucherDetail.status ===
      constantData.StatusType.ACTIVE
    )
  }

  get isExpiredOrCancelled(): boolean {
    return [
      constantData.StatusType.EXPIRED,
      constantData.StatusType.CANCELLED,
    ].includes(<constantData.StatusType>controller.voucherDetail.status)
  }

  get voucherDetail(): ProgrammaticVoucher {
    return controller.voucherDetail
  }

  get isOnScheduleTrigger(): boolean {
    return (
      <constantData.TriggerType>this.form.voucherTrigger.value ===
      constantData.TriggerType.SCHEDULE
    )
  }

  get isInvoicePaidTrigger(): boolean {
    return (
      <constantData.TriggerType>this.form.voucherTrigger.value ===
      constantData.TriggerType.INVOICE_PAID
    )
  }

  get isMembershipLevelUpTrigger(): boolean {
    return (
      <constantData.TriggerType>this.form.voucherTrigger.value ===
      constantData.TriggerType.MEMBERSHIP_LEVEL_UP
    )
  }

  get isRedeemToPoint(): boolean {
    return (
      <constantData.TermConditionType>this.form.voucherPurposeChild.value ===
      constantData.TermConditionType.REDEEM_TO_POINT
    )
  }

  get isRedeemToParcelPoint(): boolean {
    return (
      <constantData.TermConditionType>this.form.voucherPurposeChild.value ===
      constantData.TermConditionType.REDEEM_PARCEL_POINT
    )
  }

  get isDiscByAmount(): boolean {
    return (
      <constantData.TermConditionType>this.form.voucherPurposeChild.value ===
      constantData.TermConditionType.DISCOUNT_BY_AMOUNT
    )
  }

  get isDiscByPercent(): boolean {
    return (
      <constantData.TermConditionType>this.form.voucherPurposeChild.value ===
      constantData.TermConditionType.DISCOUNT_BY_PERCENTAGE
    )
  }

  get isCashback(): boolean {
    return (
      <constantData.TermConditionType>this.form.voucherPurposeChild.value ===
      constantData.TermConditionType.CASHBACK
    )
  }

  get triggerOptions(): DropDownLabelValue<string>[] {
    return controller.triggerData
  }

  get routeOptions(): Data[] {
    return routeController.cityData
  }

  get voucherPointPurposeOptions(): DropDownLabelValue<string>[] {
    return constantData.POINT_PURPOSES.map(v => {
      return {
        ...v,
        $isDisabled: true,
      }
    })
  }

  private setAllChanged(): void {
    this.hasChanged = {
      pointAmount: true,
      pointExpiry: true,
      pointPurpose: true,
      discountAmount: true,
      discountMinimum: true,
      discountPercentage: true,
      discountPercentageMinimum: true,
      discountPercentageMaximum: true,
      cashbackPercentage: true,
      cashbackPercentageMinimum: true,
      cashbackPercentageMaximum: true,
      voucherPurposeInvoiceType: true,
      expiryDay: true,
      limitUsage: true,
      startDate: true,
      startTime: true,
      endDate: true,
      endTime: true,
      budgetAmount: true,
    }
  }

  private setShipmentType(value: string): void {
    this.hasChanged.voucherPurposeInvoiceType = true
    if (this.form.voucherPurposeInvoiceType.includes(value)) {
      this.form.voucherPurposeInvoiceType = this.form.voucherPurposeInvoiceType.filter(
        item => item !== value
      )
    } else {
      this.form.voucherPurposeInvoiceType.push(value)
    }
  }

  private onUpdateVoucher(): void {
    this.setAllChanged()
    this.confirmationModal = false
    if (!this.$v.form.$invalid) {
      controller.updateProgrammaticVoucher({
        voucherId: this.$route.params.id,
        ...this.form,
      })
    } else {
      this.$notify({
        title: 'Invalid Form Program Voucher',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  @Watch('controller.isSuccessUpdate')
  onUpdateSuccess(isSuccess: boolean): void {
    if (isSuccess) {
      this.successModal = true
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'ProgrammaticVoucherPage' })
  }

  private isToggleBudgetAmount(): void {
    this.toggleBudgetAmount = !this.toggleBudgetAmount
    this.form.budgetAmount = 0
  }
}
