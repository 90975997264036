


























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import CheckBoxMinusIcon from '@/app/ui/assets/check_box_minus.vue'
import * as TypeData from '@/app/ui/components/OptionBox/interfaces'

@Component({
  inheritAttrs: false,
  components: {
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    CheckBoxMinusIcon,
  },
})
/**
 * fieldLabel: string
 * Label of the option box
 *
 * fieldDescription: string
 * Description of the option box
 *
 * options: array
 * An array of selectitems to display as the available options.
 * if key has 'disabled', the value of that gonna me throw on :disabled
 *
 *
 * preselected: array
 * Preselected items
 *
 * disabled: boolean
 * When present, it specifies that the component should be disabled.
 *
 * columns: number
 * Add columns to option box
 */
export default class OptionBox extends Vue {
  @Prop({ required: true }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private errorMessage!: string
  @Prop({ default: () => [] }) private options!: Array<
    TypeData.GroupOption<string, string>
  >
  @Prop({ default: () => [] }) private preselected!: Array<string>
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: null }) private columns!: number
  @Prop({ default: false }) private required!: boolean

  private setOptionDisabled(
    optionData: TypeData.GroupOption<string, string>
  ): boolean {
    if (optionData.disabled !== undefined) {
      return optionData.disabled
    }

    return this.disabled
  }

  private setTextColor(
    optionData: TypeData.GroupOption<string, string>,
    color: string
  ): string {
    return this.setOptionDisabled(optionData) ? 'text-gray-500' : color
  }
}
