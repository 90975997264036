var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field-wrapper",attrs:{"data-testid":"global-component__optionBox"}},[_c('div',{staticClass:"field-label",attrs:{"data-testid":"global-component__optionBox__label"}},[_c('span',{staticClass:"field-label",domProps:{"innerHTML":_vm._s(_vm.fieldLabel)}}),(_vm.required)?_c('span',{staticClass:"text-bgPrimary"},[_vm._v(" *")]):_vm._e()]),(_vm.fieldDescription)?_c('span',{class:['field-description', { error: Boolean(_vm.errorMessage) }],attrs:{"data-testid":"global-component__optionBox__description"}},[_vm._v(_vm._s(_vm.errorMessage || _vm.fieldDescription))]):_vm._e(),_c('div',{style:({ columns: _vm.columns })},_vm._l((_vm.options),function(item,idx){return _c('div',{key:idx,staticClass:"flex flex-row items-center mb-3",attrs:{"data-testid":"global-component__optionBox__item","data-testid-value":item.value}},[(item.group)?_c('div',{attrs:{"data-testid":"global-component__optionBox__item__group"}},[_c('div',{staticClass:"flex flex-row items-center mb-3"},[_c('button',_vm._b({attrs:{"disabled":_vm.disabled,"data-testid":"global-component__optionBox__item__group__event-click","data-testid-value":item.value},on:{"click":function($event){return _vm.$emit('groupClick', item.group)}}},'button',_vm.$attrs,false),[(
                item.options.every(function (option) { return _vm.preselected.includes(option.value); }
                )
              )?_c('CheckBoxCheckedIcon',{class:[
                'fill-current',
                { 'text-gray-500': _vm.disabled, 'text-bgPrimary': !_vm.disabled } ],attrs:{"data-testid":"global-component__optionBox__item__group__checked-icon","data-testid-value":item.value}}):(
                item.options
                  .filter(function (v) { return v['disabled'] === undefined; })
                  .every(function (option) { return _vm.preselected.includes(option.value); })
              )?_c('CheckBoxCheckedIcon',{class:[
                'fill-current',
                { 'text-gray-500': _vm.disabled, 'text-bgPrimary': !_vm.disabled } ],attrs:{"data-testid":"global-component__optionBox__item__group__checked","data-testid-value":item.value}}):(
                item.options.some(function (option) { return _vm.preselected.includes(option.value); }
                )
              )?_c('CheckBoxMinusIcon',{class:[
                'fill-current',
                { 'text-gray-500': _vm.disabled, 'text-bgPrimary': !_vm.disabled } ],attrs:{"data-testid":"global-component__optionBox__item__group__minus","data-testid-value":item.value}}):_c('CheckBoxUncheckedIcon',{class:[
                'fill-current',
                { 'text-gray-500': _vm.disabled, 'text-grayPensive': !_vm.disabled } ],attrs:{"data-testid":"global-component__optionBox__item__group__unchecked","data-testid-value":item.value}})],1),_c('span',{staticClass:"ml-3 text-sm text-black-2",attrs:{"data-testid":"global-component__optionBox__item__group__event-clickable","data-testid-value":item.value},on:{"click":function($event){return _vm.$emit('groupClick', item.group)}}},[_vm._v(" "+_vm._s(item.group)+" ")])]),_vm._l((item.options),function(groupItem,i){return _c('div',{key:i,staticClass:"flex flex-row items-center mb-3 ml-5"},[_c('button',_vm._b({class:_vm.setOptionDisabled(groupItem) ? 'cursor-not-allowed' : '',attrs:{"disabled":_vm.setOptionDisabled(groupItem)},on:{"click":function($event){return _vm.$emit('click', groupItem.value)}}},'button',_vm.$attrs,false),[(_vm.preselected.includes(groupItem.value))?_c('CheckBoxCheckedIcon',{class:[
                'fill-current ya',
                _vm.setTextColor(groupItem, 'text-bgPrimary') ]}):_vm._e(),(!_vm.preselected.includes(groupItem.value))?_c('CheckBoxUncheckedIcon',{class:[
                'fill-current oh',
                _vm.setTextColor(groupItem, 'text-grayPensive') ],attrs:{"fill":_vm.setOptionDisabled(groupItem) ? 'rgb(211, 211, 212)' : 'white'}}):_vm._e()],1),(_vm.$scopedSlots.label)?_vm._t("label",null,{"label":groupItem.label,"value":groupItem.value}):_c('span',{staticClass:"ml-3 text-sm text-black-2",on:{"click":function($event){return _vm.$emit('click', groupItem.value)}}},[_vm._v(" "+_vm._s(groupItem.label)+" ")])],2)})],2):[_c('button',_vm._b({class:_vm.setOptionDisabled(item) ? 'cursor-not-allowed' : '',attrs:{"disabled":_vm.setOptionDisabled(item),"data-testid":"global-component__optionBox__item__event-click","data-testid-value":item.value},on:{"click":function($event){return _vm.$emit('click', item.value)}}},'button',_vm.$attrs,false),[(_vm.preselected.includes(item.value))?_c('CheckBoxCheckedIcon',{class:['fill-current', _vm.setTextColor(item, 'text-bgPrimary')],attrs:{"data-testid":"global-component__optionBox__item__checked","data-testid-value":item.value}}):_vm._e(),(!_vm.preselected.includes(item.value))?_c('CheckBoxUncheckedIcon',{class:['fill-current', _vm.setTextColor(item, 'text-grayPensive')],attrs:{"data-testid":"global-component__optionBox__item__unchecked","data-testid-value":item.value,"fill":_vm.setOptionDisabled(item) ? 'rgb(211, 211, 212)' : 'white'}}):_vm._e()],1),(_vm.$scopedSlots.label)?_vm._t("label",null,{"label":item.label,"value":item.value}):_c('span',{staticClass:"ml-2.5 text-sm text-black-2",attrs:{"data-testid":"global-component__optionBox__item__event-click-label","data-testid-value":item.value},on:{"click":function($event){return _vm.$emit('click', item.value)}}},[_vm._v(" "+_vm._s(item.label)+" ")])]],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }