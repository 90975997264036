



































import { Component, Prop, Vue } from 'vue-property-decorator'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  inheritAttrs: false,
  components: {
    DateTimePicker,
    IconWarningTriangle
  },
})
export default class VoucherDatePicker extends Vue {
  @Prop({ required: true }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private fieldSuffix!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private required!: boolean
}
